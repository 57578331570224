
































































































































































import * as moment from 'moment';
import _ from 'lodash';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select-bordered.vue';
import { defaultPercentageVal, defaultTrendVal } from '@/modules/cars/modules/settings/config/alerts-notification.config';
import { Inject } from 'inversify-props';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsCompsetsAlertService, { CarsCompsetsAlertServiceS } from '@/modules/cars/car.compsets-alert.service';
import CarsCompetitorsAlertService, { CarsCompetitorsAlertServiceS } from '@/modules/cars/car.competitors-alert.service';
import CarsBrandAlertService, { CarsBrandAlertServiceS } from '@/modules/cars/car.brand-alert.service';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CAR_FUEL_TYPE from '@/modules/cars/constants/car-fuel-type.constant';
import CustomRadioGroup from '@/modules/common/components/ui-kit/custom-radio-group.vue';
import { DatePicker, RadioGroup } from 'element-ui';
import { PickUpDynamicRangeList, PickUpDynamicRange, PickUpConfigSelection, UpToDynamicRangeList } from '@/modules/cars/constants/car-alert-settings.constant';
import CarsRateAlertService, { CarsRateAlertServiceS, notificationMessages } from '@/modules/cars/car.rate-alert.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import CarsRateAlertConfigurationModel from '@/modules/cars/models/cars-rate-alert-configuration.model';
import StyledSearchbar from '@/modules/common/components/styled-searchbar.vue';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import CarsBaseAlertConfigs from '@/modules/cars/models/cars-base-alert-configs.model';
import { ICarAlertConfig } from '../interfaces/alert-interfaces';
import { CarAlertTypeList } from '../enums/alerts.enum';

@Component({
    components: {
        CustomSelect,
        ModalWrapper,
        CustomMultiSelect,
        CustomRadioGroup,
        RadioGroup,
        DatePicker,
        StyledSearchbar,
    },
})

export default class CarRateAlertConfigsPopup extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsFiltersServiceS) carsFiltersService!: CarsFiltersService;
    @Inject(CarsRateAlertServiceS) private carsRateAlertService!: CarsRateAlertService;
    @Inject(NotifyServiceS) private notifyService!: NotifyService;
    @Inject(CarsCompsetsAlertServiceS) private carsCompsetsAlertService!: CarsCompsetsAlertService;
    @Inject(CarsCompetitorsAlertServiceS) private carsCompetitorsAlertService!: CarsCompetitorsAlertService;
    @Inject(CarsBrandAlertServiceS) private carsBrandAlertService!: CarsBrandAlertService;

    @Prop({ type: String })
    public id?: string;

    @Prop({ type: String, default: CarAlertTypeList.RATE_DIFF })
    public alertType!: string;

    currentAlertType: string = this.alertType || this.alertTypeList[0].value;
    currentPercentage = this.initPercentageVal;
    currentTrendValue = this.currentConfig ? this.currentConfig.trend : this.trendValItems[0].value;
    currentPosValues: Record<string, any>[] = this.currentConfig ? this.currentConfig.pos.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    currentLokValues: Record<string, any>[] = this.currentConfig ? this.currentConfig.lors.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    currentDataSource: string = 'Brand';
    currentLocationValues: Record<string, any>[] = this.currentConfig ? this.currentConfig.locationIds.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    currentCategoryValue: Record<string, any>[] = this.currentConfig ? this.currentConfig.carCategories.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    currentFuelTypeValues: Record<string, any>[] = this.currentConfig ? this.currentConfig.fuelType.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];
    selectedDate: Date[] = this.currentConfig && this.currentConfig.pickupDates.fixed
        ? this.currentConfig.pickupDates.fixed : [new Date(), new Date()];
    currentDynamicRangeFrom = this.currentConfig && this.currentConfig.pickupDates.dynamic
        ? this.currentConfig.pickupDates.dynamic[0] : PickUpDynamicRange.TODAY;
    currentDynamicRangeTo = this.currentConfig && this.currentConfig.pickupDates.dynamic
        ? this.currentConfig.pickupDates.dynamic[1] : 1;
    selectedPicker: string = this.currentConfig && this.currentConfig.pickupDates.dynamic
        ? PickUpConfigSelection.DYNAMIC : PickUpConfigSelection.FIXED;
    locationQuery: string | null = null;
    countryQuery: string | null = null;
    currentCountryValue: string = this.currentConfig ? this.countryFromConfig : this.carsService.storeState.settings.country || this.countryItems[0].value;
    currentCompetitor: Record<string, any>[] = this.currentConfig ? this.currentConfig.competitors.map(value => ({
        name: String(value),
        value: String(value),
    })) : [];

    get isDisabled() {
        const disabled = !this.currentLocationValues.length || !this.currentPosValues.length || !this.currentCategoryValue.length
            || !this.currentFuelTypeValues.length || !this.currentLokValues.length;
        return this.currentAlertType === CarAlertTypeList.BRAND_DIFF ? disabled : (disabled || !this.currentCompetitor.length);
    }

    get countryFromConfig() {
        if (this.currentConfig?.locationIds) {
            const countries = this.currentConfig.locationIds.map(id => this.carsFiltersService.getCountryByPickUpLocationId(id));
            if (_.uniq(countries).length === 1) {
                return countries[0] || COUNTRIES_ANY;
            }
        }
        return COUNTRIES_ANY;
    }

    get initialCurrentCompetitor() {
        let initialCompetitor = (this.currentDataSource ? this.carsService.storeState.settings.dataSourceCompetitors[this.currentDataSource] : []) || [];
        if (this.currentConfig && this.currentConfig.competitors) {
            initialCompetitor = this.currentConfig.competitors;
        }
        return initialCompetitor.map(competitor => ({ name: competitor, value: competitor }));
    }

    get competitorsList() {
        if (!this.currentDataSource && !this.currentPosValues) {
            return [];
        }

        let competitorsFilter: string[] = [];
        this.currentPosValues.forEach(pos => {
            const competitorsData = this.carsFiltersService.getCompetitorsFilterByDataSource(this.currentDataSource, pos.value, this.currentCountry);
            competitorsFilter = competitorsFilter.length ? _.intersection(competitorsFilter, competitorsData) : competitorsData;
        });
        this.currentCompetitor = this.currentCompetitor.filter(competitor => competitorsFilter.includes(competitor.value));
        if (!competitorsFilter) {
            return [];
        }
        const data = competitorsFilter.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data.sort((a, b) => {
            if (a.value < b.value) {
                return -1;
            }
            return 1;
        });
    }

    get currentCountry() {
        return this.currentCountryValue;
    }

    set currentCountry(value) {
        this.currentCountryValue = value;
        this.currentLocationValues = [];
    }

    set currentLocations(values: Record<string, any>[]) {
        this.currentLocationValues = values;
    }

    get currentLocations() {
        return this.currentLocationValues;
    }

    get percentageVal() {
        return this.currentAlertType === CarAlertTypeList.COMPSET_DIFF ? defaultPercentageVal : defaultPercentageVal.slice(1);
    }

    get trendValItems() {
        return defaultTrendVal;
    }

    get currentLorValue() {
        return this.carsService.storeState.settings.lor;
    }

    get getPosList() {
        const { allowedLocationPerPos } = this.carsFiltersService;
        if (!allowedLocationPerPos) {
            return [];
        }

        const selectedLocations = this.currentLocationValues.map(location => location.value);
        const posList = Array.from(allowedLocationPerPos).filter(([key, value]) => selectedLocations.includes(key)).map(([key, value]) => value);
        const availablePos = _.intersection(...posList);
        this.currentPosValues = this.currentPosValues.filter(pos => availablePos.includes(pos.value));
        return Array.from(availablePos).map(value => ({
            name: String(value),
            value: String(value),
        }));
    }

    get getLokList() {
        let allLors: string[] = [];
        this.currentLocationValues.forEach(location => {
            this.currentPosValues.forEach(pos => {
                const currentLor = this.carsFiltersService.getAvailableLors(
                    location.value,
                    pos.value,
                    this.currentDataSource,
                    '',
                    false,
                );
                allLors = [...allLors, ...currentLor];
            });
        });
        allLors = _.uniq(allLors);
        this.currentLokValues = this.currentLokValues.filter(lor => allLors.includes(lor.value));
        if (allLors?.length === 0) {
            return [];
        }
        const lors = allLors.map(Number).sort((a, b) => a - b).map(value => ({
            name: String(value),
            value: String(value),
        }));
        return lors;
    }

    get getDataSourceList() {
        const dataSource = ['Brand'];

        const data = dataSource.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get getLocationList() {
        const filteredLocations = this.carsFiltersService.filterPickUpCitiesByCountry(this.currentCountry);
        if (!filteredLocations) {
            return [];
        }
        let data = filteredLocations.map(value => ({
            name: String(value.locationName),
            value: String(value.locationId),
        }));

        if (this.locationQuery) {
            const searchPattern = new RegExp(this.locationQuery.toLowerCase());
            data = data.filter(location => searchPattern.test(location.name.toLowerCase()));
        }
        return data;
    }

    get getCarCategoryList() {
        const categories = this.carsFiltersService.allCarClasses;
        if (!categories) {
            return [];
        }
        const data = categories.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get fuelTypesList() {
        const fuleTypes = CAR_FUEL_TYPE;
        if (!fuleTypes) {
            return [];
        }
        const data = fuleTypes.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get getPickUpDynamicRangeList() {
        const pickUpDynamicRange = PickUpDynamicRangeList;
        if (!pickUpDynamicRange) {
            return [];
        }
        const data = pickUpDynamicRange.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get upToDynamicRangeList() {
        const upToDynamicRangeList = UpToDynamicRangeList;
        if (!upToDynamicRangeList) {
            return [];
        }
        const data = upToDynamicRangeList.map(value => ({
            name: String(value),
            value: String(value),
        }));
        return data;
    }

    get currentConfig(): CarsBaseAlertConfigs | CarsRateAlertConfigurationModel | null {
        const { data } = this.alertService;
        if (this.id && data) {
            return data.filter(config => config.id === this.id)[0];
        }
        return null;
    }

    get alertTypeList(): Record<string, string>[] {
        return Object.values(CarAlertTypeList).map(value => ({
            name: value,
            value,
        }));
    }

    get alertService() {
        switch (this.currentAlertType) {
            case CarAlertTypeList.COMPSET_DIFF:
                return this.carsCompsetsAlertService;
            case CarAlertTypeList.COMPETITORS_DIFF:
                return this.carsCompetitorsAlertService;
            case CarAlertTypeList.BRAND_DIFF:
                return this.carsBrandAlertService;
            default:
                return this.carsRateAlertService;
        }
    }

    @Watch('percentageVal.length')
    onPercentageValChanged() {
        this.currentPercentage = this.initPercentageVal;
    }

    async handleAddOrUpdateButton() {
        if (this.isDisabled) {
            this.notifyService.error(notificationMessages.FAILED);
            return;
        }
        const config: ICarAlertConfig = {
            location_ids: this.currentLocationValues.map(loc => loc.value),
            pos: this.currentPosValues.map(pos => pos.value),
            car_categories: this.currentCategoryValue.map(category => category.value),
            fuel_type: this.currentFuelTypeValues.map(type => type.value),
            data_source: [this.currentDataSource],
            lors: this.currentLokValues.map(lok => lok.value),
            is_active: true,
            competitors: this.currentCompetitor.map(competitor => competitor.value),
            trend: this.currentTrendValue,
            ratio: this.currentPercentage,
        };

        if (this.selectedPicker === PickUpConfigSelection.FIXED && this.selectedDate.length) {
            config.pickup_dates = {
                fixed: this.selectedDate.map(date => moment.utc(date).format('YYYY-MM-DD')),
            };
        } else {
            config.pickup_dates = {
                dynamic: [this.currentDynamicRangeFrom, this.currentDynamicRangeTo],
            };
        }

        if (this.currentAlertType === CarAlertTypeList.BRAND_DIFF) {
            delete config.competitors;
        }

        if (this.id) {
            await this.alertService.updateAlertConfiguration(config, this.id);
        } else {
            await this.alertService.saveAlertConfiguration(config);
        }
        this.notifyService.success(this.successMessage);
        this.alertService.resetLoadings();
        this.close();
    }

    close() {
        this.$emit('close');
    }

    get successMessage() {
        return this.id ? notificationMessages.UPDATE_SUCCESS : notificationMessages.ADD_SUCCESS;
    }

    get actionButtonText() {
        return this.id ? 'Update' : 'Add';
    }

    get countryItems() {
        let clustersKeys = this.carsFiltersService.getClustersKeys();
        const initialCountry = [{ name: COUNTRIES_ANY, value: COUNTRIES_ANY }];
        if (!clustersKeys) {
            return initialCountry;
        }

        if (this.countryQuery) {
            const searchPattern = new RegExp(this.countryQuery.toLowerCase());
            clustersKeys = clustersKeys.filter(country => searchPattern.test(country.value.toLowerCase()));
        }

        return clustersKeys;
    }

    get initPercentageVal() {
        return this.currentConfig ? (this.currentConfig as CarsRateAlertConfigurationModel).ratio : this.percentageVal[0].value;
    }

    get isCompetitorVisible(): boolean {
        return this.currentAlertType !== CarAlertTypeList.BRAND_DIFF;
    }

    get countryStyle() {
        return !this.isCompetitorVisible ? { width: '45rem' } : { };
    }

    handleCountryListChange(query: string) {
        this.countryQuery = query;
    }

    handleLocationListChange(query: string) {
        this.locationQuery = query;
    }
}

